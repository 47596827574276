<template>
  <div class="detail">
    <detail-header title="订单详情"></detail-header>

    <div class="detail_main page_width">
      <!-- 订单信息 -->
      <div class="info tw-flex tw-justify-between">
        <div class="info_left">
          <order-title title="订单信息"></order-title>

          <div class="info_item">
            <p class="info_item_label">订单编号：</p>
            <p class="info_item_value">{{order.orderNo}}</p>
          </div>
          <div class="info_item">
            <p class="info_item_label">下单时间：</p>
            <p class="info_item_value">{{order.createdAt}}</p>
          </div>
          <div class="info_item">
            <p class="info_item_label">订单状态：</p>
            <p class="info_item_value">
              {{ orderStatus }}
            </p>
          </div>
        </div>
        <div class="info_price tw-flex tw-items-end">
          <template v-if="requirement.id">
            <p class="info_price_num text_size_24 tw-font-bold">
              <span
                class="text_size_16 tw-font-bold"
                v-if="order.totalAmount > 0">
                ￥
              </span>
              {{ order.totalAmount === '面议' ? '面议' : order.totalAmount + '元' }}
            </p>
            <p class="info_price_time text_size_14 tw-flex-1 tw-text-right">
              {{ requirement.createdAt }}
            </p>
          </template>
        </div>
      </div>

      <!-- 需求信息 -->
      <div class="info" v-if="requirement.id">
        <order-title title="需求信息" />

        <el-row :gutter="50">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">需求名称：</p>
              <p class="info_item_value">{{requirement.title}}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">需求类别：</p>
              <p class="info_item_value">{{requirement.serviceTypeName}}</p>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">行业领域：</p>
              <p class="info_item_value">{{requirement.industryName.toString()}}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">技术领域：</p>
              <p class="info_item_value">{{requirement.technicalName.toString()}}</p>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">时限要求：</p>
              <p class="info_item_value">{{requirement.startAt}} - {{requirement.endAt}}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">需求描述：</p>
              <p class="info_item_value">{{requirement.description}}</p>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">关键字：</p>
              <p class="info_item_value">{{requirement.keywords}}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">预算金额：</p>
              <p class="info_item_value">{{ requirement.budget }}元</p>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">需求所属企业名称：</p>
              <p class="info_item_value">{{requirement.enterpriseName}}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">统一社会信用代码：</p>
              <p class="info_item_value">{{requirement.socialCreditCode}}</p>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">企业所在区域：</p>
              <p class="info_item_value">{{ requirement.province }}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">联系人：</p>
              <p class="info_item_value">{{ requirement.contacts }}</p>
            </div>
          </el-col>
        </el-row>

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">联系手机：</p>
              <p class="info_item_value">{{requirement.contactMobile}}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">联系邮箱：</p>
              <p class="info_item_value">{{ requirement.contactEmail }}</p>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 完结附件 -->
      <div class="info" v-if="order.logicStatus === globalVar.REQUIRE_ORDER_STATUS_2">
        <order-title title="完结附件" />

        <el-row
          :gutter="50"
          style="margin-top:10px;">
          <el-col :span="12">
            <div class="info_item">
              <p class="info_item_label">完结附件：</p>
              <div class="info_item_value">
                <files-list :list="order.attachments"></files-list>
              </div>
            </div>
          </el-col>
        </el-row>

      </div>

      <!-- 报价信息 -->
      <div class="info">
        <order-title title="报价信息" />

        <div class="info_item">
          <p class="info_item_label">报价：</p>
          <p class="info_item_value">{{order.totalAmount}}元</p>
        </div>
        <div class="info_item">
          <p class="info_item_label">联系人：</p>
          <p class="info_item_value">{{order.contactName}}</p>
        </div>
        <div class="info_item">
          <p class="info_item_label">联系电话：</p>
          <p class="info_item_value">{{order.contactNumber}}</p>
        </div>
        <!-- <div class="info_item">
          <p class="info_item_label">代表机构：</p>
          <p class="info_item_value">{{order.orgName}}</p>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
import orderTitle from '../../service-order/detail/order-title'
import filesList from '@/components/upload-file/files-list'

import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    orderTitle,
    filesList
  },
  data () {
    return {
      order: {},
      dialogVisible: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    requirement () {
      return this.order.requirement || {}
    },
    orderStatus () {
      return {
        [globalVar.REQUIRE_ORDER_STATUS_1]: '对接中',
        [globalVar.REQUIRE_ORDER_STATUS_2]: '已完成'
      }[this.order.logicStatus]
    }
  },
  mounted () {
    this.getOrderDetail()
  },
  methods: {
    getOrderDetail () {
      api.getRequireOrderDetail(this.id).then(res => {
        if (res.data.code === 0) {
          this.order = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 20px 50px 38px 50px;
    &_btn {
      padding: 40px 20px;
    }
  }
}
.info {
  &+ & {
    margin-top: 20px;
  }
  &_item {
    min-height: 30px;
    padding: 5px 0;
    display: flex;
    &+ & {
      margin-top: 10px;
    }
    &_label {
      width: 150px;
      flex: none;
      line-height: 20px;
      padding-left: 20px;
      color: #909399;
    }
    &_value {
      flex: 1;
      word-break: break-all;
      overflow: hidden;
      line-height: 20px;
      color: #303133;
    }
  }
  &_price {
    width: 500px;
    height: 66px;
    margin: 60px 50px 0 0;
    padding: 0 22px 15px 30px;
    background-image: url('../../../../assets/images/price_bg.png');
    &_num {
      color: #FF4343;
      line-height: 33px;
    }
    &_time {
      color: #303133;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }
}
</style>
